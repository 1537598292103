import routes from './routes';
import { DEFAULT_LANGUAGE, OMIT_DEFAULT_LANGUAGE } from './const';

/*
const treeTest = [
  {
    item: 'item 1',
    link: '/item1',
    children: [
      {
        item: 'item 1_1',
        link: '/item1/i1',
      },
      {
        item: 'item 1_2',
        link: '/item1/i2',
        children: [
          {
            item: 'item 1_2_1',
            link: '/item1/i2/i1',
          },
          {
            item: 'item 1_2_2',
            link: '/item1/i2/i2',
          },
        ],
      },
      {
        item: 'item 1_3',
        link: '/item1/i3',
      },
    ]
  },
  {
    item: 'item 2',
    link: '/item2',
  }
];
 */
const parseLevel = ({routes, parentPath = '', i18n}) => {

  const links = [];
  const { t } = i18n;

  Object.keys(routes).forEach(key => {
    if (key === '*' || key === 'index') {
      return;
    }
    const path = `${parentPath !== '/' 
      ? parentPath 
      : ''}${parentPath.charAt(parentPath.length -1 ) !== '/' && key.charAt(0) !== '/' 
      ? '/' 
      : ''}${key}${key.charAt(key.length - 1) === '/' ? '' : '/'}`;
    const item = routes[key];
    if (!item) {
      return;
    }
    const { title, children } = item;
    links.push({
      item: t(title),
      link: path,
      ...(children && {children: parseLevel({
            routes: children,
            parentPath: path,
            i18n,
          }
        )})
    });
  });
  return links;
}

const linksTree = (i18n, includeRoot = false) => {
  // const { i18n } = useTranslation();
  // return { i18n };

  // console.log('linksTree ---');
  // console.log(i18n);

  if (!i18n) {
    return;
  }
  const isDefaultLanguage = i18n.resolvedLanguage === DEFAULT_LANGUAGE;
  const rootNode = isDefaultLanguage && OMIT_DEFAULT_LANGUAGE
    ? {'/' : routes['/']}
    : {[`/${i18n.resolvedLanguage}`]: routes['/:lang']};

  const tree = parseLevel({
    routes: rootNode,
    i18n,
  });

  return includeRoot
    ? tree
    : tree[0]?.children;
}

export default linksTree;
