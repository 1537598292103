import React, {FC, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import loadable from '@loadable/component';
import { Link } from 'react-router-dom';
import { DEFAULT_LANGUAGE, OMIT_DEFAULT_LANGUAGE } from '../../../const';
import { Icons } from '../../Components';
import { IconButton } from '../Buttons';
import Links from '../../../Links';
import linksTree from '../../../linksTree';
import { INavigationMenuProps } from '../Forms/Navigation/NavigationTree';
import { Checkbox, CheckboxLabelled, CheckboxTextLabelled, CheckboxBlockLabelled } from '../Forms';
import theme from "../../theme";
// import {}

const LangMenu = loadable(() => import('../../../LangMenu'));

// example of loadable non default component with typing
const NavigationTree: FC<INavigationMenuProps> = loadable(() => import('../Forms/Navigation/NavigationTree'), {
  resolveComponent: (components) => components.NavigationTree,
});

const StyledDiv = styled.div`
  //background-color: #739aa3;
  background-color: ${({theme}) => theme.themeColors.support[300]};
  color: white;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const WrapperLeft = styled.div`

`;

const WrapperCenter = styled.div`
  flex-grow: 1;
`;

const WrapperCenterAligned = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 20px;
`;

const WrapperRight = styled.div`

`;

// const StyledBlock = styled.div`
//   display: inline-block;
//   width: 100px;
//   height: 100px;
//   background-color: grey;
// `;

// interface ILinksWrapperProps {
//
// }

// const NavWrapper = styled.div`
//   display: inline-block;
// `;
//
// const LinksWrapper = styled.nav<ILinksWrapperProps>`
//   display: ${(props) => props.showMenu && `inline-block` || 'none'};
//   position: absolute;
//   left: 0;
//   top: 0;
// `;
//
// // interface INavMenuProps {
// //   setShowMenu: (val: boolean) => void;
// //
// // }

// const NavMenu: FC = (
//   {
//     // setShowMenu,
//     // showMenu,
//   }
// ) => {
//
//   const [showMenu, setShowMenu] = useState<boolean | string>(false);
//
//   const wrapper = useRef(null);
//
//   const toggleNav = () => {
//     setShowMenu(!showMenu);
//   }
//
//   const clickNav = () => {
//     setShowMenu(false);
//   };
//
//   const handleClickOutside = (e: MouseEvent): void => {
//     const domNode = wrapper?.current;
//     const target = e.target as HTMLElement;
//
//     if (!domNode?.contains(target)) {
//       setShowMenu(false);
//     }
//   };
//
//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside, true);
//     return (): void => {
//       document.removeEventListener('mousedown', handleClickOutside, true);
//     }
//   });
//
//   return (
//     <NavWrapper
//       ref={wrapper}
//     >
//       <IconButton
//         data-marker="icon-button"
//         title={'Navigation menu'}
//         onClick={toggleNav}
//       >
//         <Icons.Menu />
//       </IconButton>
//       <LinksWrapper
//         showMenu={showMenu}
//         onClick={clickNav}
//       >
//         <Links />
//       </LinksWrapper>
//     </NavWrapper>
//   )
// };

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const NavMenuTrigger = () => {
  const { t } = useTranslation();
  return (
    <IconWrapper
      title={t('Navigation menu')}
    ><Icons.Menu /></IconWrapper>

    // <IconButton
    //   data-marker="icon-button"
    //   title={t('Navigation menu')}
    //   // onClick={toggleNav}
    // >
    //   <Icons.Menu />
    // </IconButton>
  )
}

const StyledA = styled(Link)`
  color: ${(props) => props.theme.palette.white};
  text-decoration: none;
`;

const Header = () => {
  const { t, i18n } = useTranslation();

  // const treeRef = useRef(linksTree(i18n));

  const [tree, setTree] = useState(linksTree(i18n));

  const isDefaultLanguage = i18n.resolvedLanguage === DEFAULT_LANGUAGE;
  const rootUrl = isDefaultLanguage && OMIT_DEFAULT_LANGUAGE
    ? `/`
    : `/${i18n.resolvedLanguage}/`;

  // useEffect(() => {
  //   treeRef.current = linksTree(i18n);
  // }, []);

  useEffect(() => {
    // treeRef.current = linksTree(i18n);
    setTree(linksTree(i18n));
  }, [i18n.language]);

  return (
    <>
      <StyledDiv>
        <WrapperLeft>
          <NavigationTree
            tree={tree}
            trigger={<NavMenuTrigger />}
          />
        </WrapperLeft>
        <WrapperCenter>
          <WrapperCenterAligned>
            <StyledA
              to={rootUrl}
            >
              {t('Web-craft tools')}
            </StyledA>
          </WrapperCenterAligned>
        </WrapperCenter>
        <WrapperRight>
          <LangMenu />
        </WrapperRight>
      </StyledDiv>
    </>
  )
}

export default Header;
